@import url('https://fonts.googleapis.com/css2?family=Actor&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap');

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  background: #141141
    linear-gradient(180deg, #9f1f63 0%, #141141 52%, #262262 108%);
  background-attachment: fixed;
  color: white;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.Header a.logo {
  display: block;
  width: 144px;
  height: 120px;
  margin: 40px auto 16px;
  background-image: url('./images/logo.png');
  background-position: center;
  background-size: cover;
  color: transparent;
  user-select: none;
}

.Header h3 a {
  color: #dcdcdc;
  text-decoration: underline;
}
.Header h3 a:hover {
  color: #9f1f63;
}


.Header h2,
.Claims h2 {
  margin: 16px auto;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: normal;
}

.Header h3 {
  width: 692px;
  margin: 16px auto 64px;
  font-family: Actor;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4em;
}

.Dashboard .container {
  position: relative;
  overflow: hidden;
  min-height: 310px;
  padding: 24px 72px 0;
  margin-bottom: 40px;
  background-color: #f1f1f3;
  border-radius: 10px;
  color: #9f1f63;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
}

@media (max-width: 575.98px) {
  .Dashboard .container {
    padding: 24px 24px 0;
  }
}

.Dashboard .container .spinner-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.Dashboard .row,
.Dashboard .col {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}

.Dashboard .capsules {
  justify-content: space-between;
}

.Dashboard .capsules .col {
  flex: 0;
  padding-top: 16px;
  color: #222;
}

.Dashboard .capsules .pool {
  flex: 1;
}

.Dashboard .capsules .capsule {
  position: relative;
  width: 300px;
  height: 72px;
  padding: 0 16px;
  margin: 20px auto;
  background-color: #f0f0f3;
  border-radius: 36px;
  box-shadow: -5px -5px 30px #fff, 5px 5px 30px rgba(174, 174, 192, 0.5);
  color: #9f1f63;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-align: center;
  white-space: nowrap;
}

.Dashboard .capsules .label {
  font-weight: 600;
  white-space: nowrap;
}

.Dashboard .capsules .pool .label {
  font-size: 1.1em;
}

@media (max-width: 1199.98px) {
  .Dashboard .capsules .pool {
    flex-basis: 100%;
    order: -1;
  }
}

@media (max-width: 991.98px) {
  .Dashboard .capsules .label {
    font-size: 12px;
    font-weight: 600;
  }

  .Dashboard .capsules .pool .label {
    font-size: 14px;
  }

  .Dashboard .capsules .capsule {
    width: 180px;
    height: 50px;
    margin: 8px auto 16px;
    font-size: 24px;
    line-height: 50px;
  }

  .Dashboard .capsules .pool {
    flex-basis: 0;
    order: 0;
  }
}

@media (max-width: 767.98px) {
  .Dashboard .capsules .pool {
    flex-basis: 100%;
    order: -1;
  }
}

@media (max-width: 423.98px) {
  .Dashboard .capsules .col {
    flex-basis: 100%;
  }

  .Dashboard .capsules .capsule {
    width: 100%;
  }
}

.Dashboard .capsule.shift {
  padding-left: 1em;
}

.Dashboard .capsules .capsule .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  margin: auto;
}

.Dashboard .capsules .capsule .myst {
  padding-left: 0.2em;
  font-size: 0.4em;
}

.Dashboard .progress {
  height: auto;
  margin: 16px 0;
}

.Dashboard .progress .bar {
  overflow: hidden;
  width: 100%;
  height: 20px;
  background-color: #dedbe2;
  border-radius: 10px;
}

.Dashboard .progress .value {
  height: 20px;
  background: linear-gradient(90deg, #262262 -10%, #9f1f63 90%);
  border-radius: 10px;
}

.Dashboard .periods {
  position: relative;
  padding: 8px 0;
}

.Dashboard .periods .label {
  display: flex;
  flex: 0 0 100px;
  margin: 8px 0;
  color: #821e53;
  font-size: 17px;
}

.Dashboard .periods .label .icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.Dashboard .periods .value {
  margin: 8px 0;
}

@media (max-width: 991.98px) {
  .Dashboard .periods .value {
    order: 1;
    text-align: center;
  }
}

.Dashboard .periods .period {
  padding: 16px;
  margin: -16px -8px;
  color: #dfdce4;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.Dashboard .periods .period.active {
  background-image: url('./images/active.svg');
  background-position: center;
  background-repeat: no-repeat;
  color: #9f1f63;
}

.Dashboard .periods .days {
  margin: 8px 0;
  text-align: right;
  white-space: nowrap;
}

.Dashboard .info {
  min-height: 180px;
  align-items: center;
  padding: 40px 72px;
  border-top: 1px solid #d8d8d8;
  margin: 16px -72px 0;
  background: linear-gradient(180deg, #fff 0%, #dcdcdc 92%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.Dashboard .info p {
  margin: 0;
  white-space: nowrap;
}

.Dashboard .info .col {
  padding: 0 8px;
  text-align: left;
}

.Dashboard .info .col.interest {
  flex: 6;
  padding-top: 0;
  padding-left: 0;
  color: #9f1f63;
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
}

.Dashboard .info .col.interest p {
  width: 240px;
  white-space: initial;
}

.Dashboard .info .col.greater {
  flex: 3;
}

.Dashboard .info .col.find {
  flex: 6;
}

.Dashboard .info .col.uniswap {
  padding-right: 0;
  padding-bottom: 0;
}

.Dashboard .info .btn {
  min-width: 172px;
  margin-top: 8px;
  background-color: #9f1f63;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
}

.Dashboard .info .btn:hover,
.Dashboard .info .btn:active {
  background: linear-gradient(180deg, #821e53 0%, #9f1f63 100%);
  box-shadow: -5px -5px 30px #fff, 5px 5px 30px rgba(174, 174, 192, 0.5);
}

@media (max-width: 991.98px) {
  .Dashboard .info {
    flex-direction: column;
    padding: 32px 0;
  }

  .Dashboard .info .col {
    padding: 12px 0;
    text-align: center;
  }

  .Dashboard .info .col.interest p {
    width: 360px;
    margin: auto;
  }

  .Dashboard .info .col.greater .icon {
    height: 32px;
    transform: rotate(90deg);
  }
}

.Claims {
  padding: 16px 0 32px;
  margin: 32px 0;
}

.Claims .container {
  position: relative;
  overflow: hidden;
  min-height: 312px;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  color: #9f1f63;
}

.Claims .container .spinner-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.Claims .table-wrapper {
  position: relative;
  overflow: auto;
  max-height: 90vh;
  padding: 0 4px 0 20px;
}

.Claims table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.85rem;
}

.Claims thead p {
  margin: 0;
  white-space: nowrap;
}

.Claims thead th {
  position: sticky;
  top: 0;
  height: 72px;
  padding: 0.7rem;
  padding-right: 1.4rem;
  border-top: 0 none;
  border-bottom: 2px solid #9f1f63;
  background-color: #fff;
  text-align: left;
  vertical-align: middle;
}

.Claims thead th:first-child {
  box-shadow: -4px 0 white;
}

.Claims thead th:last-child {
  box-shadow: 4px 0 white;
}

.Claims thead th:hover {
  background-color: #f4f3f7;
  cursor: pointer;
}

.Claims thead th.active {
  background-color: #9f1f63;
  color: #fff;
}

.Claims thead th.active:hover {
  background: linear-gradient(180deg, #821e53 0%, #9f1f63 100%);
}

.Claims thead th.active .currency {
  color: #fff;
}

.Claims thead .chevron {
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  margin: auto;
  opacity: 0;
}

.Claims thead th.active .chevron {
  opacity: 1;
}

.Claims thead th.desc .chevron {
  transform: rotate(180deg);
}

.Claims tbody td {
  padding: 0.4rem 0.7rem;
  line-height: 2;
  text-align: left;
  vertical-align: middle;
}

.Claims tbody td:first-child {
  text-align: center;
}

.Claims thead th:not(:last-child),
.Claims tbody td:not(:last-child) {
  border-right: 1px solid #e2e8ee;
}

.Claims tbody tr:hover {
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
}

.Claims tbody tr.participant {
  cursor: pointer;
}

.Claims tbody tr.participant.current {
  color: #9f1f63;
  font-weight: 600;
}

.Claims tbody .claim {
  padding: 0 1em;
  border: 0 none;
  margin-left: 0.5em;
  background-color: #9f1f63;
  color: #fff;
  float: right;
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  opacity: 0;
}

.Claims tbody .claim:hover,
.Claims tbody .claim:active {
  background: linear-gradient(180deg, #821e53 0%, #9f1f63 100%);
  box-shadow: -5px -5px 30px #fff, 5px 5px 30px rgba(174, 174, 192, 0.5);
}

.Claims tbody tr:hover .claim {
  opacity: 1;
}

.Footer {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  margin: auto;
  background-color: #060205;
  box-shadow: 40px 0 #060205;
}

.Footer .row {
  justify-content: center;
}

.Footer .description {
  display: flex;
  flex: 1 0 240px;
  flex-direction: column;
  align-self: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
}

@media (max-width: 767.98px) {
  .Footer .description {
    flex-basis: 100%;
    order: 1;
  }
}

.Footer .description p {
  margin: 2px 0;
  color: #6f6f6f;
}

.Footer .description a {
  color: #821e53;
}

.Footer .links {
  display: flex;
  flex: 0 0 288px;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Footer .links a {
  display: block;
  width: 48px;
  height: 48px;
  margin: 8px;
  background-position: center;
  background-size: cover;
  color: transparent;
  user-select: none;
}

.Footer .links a.medium {
  background-image: url('./images/medium.png');
}

.Footer .links a.newsletter {
  background-image: url('./images/newsletter.png');
}

.Footer .links a.youtube {
  background-image: url('./images/youtube.png');
}

.Footer .links a.twitter {
  background-image: url('./images/twitter.png');
}

.Footer .links a.telegram {
  background-image: url('./images/telegram.png');
}

.Footer .links a.reddit {
  background-image: url('./images/reddit.png');
}

.Footer .links a.blog {
  background-image: url('./images/blog.png');
}

.Footer .links a.discord {
  background-image: url('./images/discord.png');
}

.Popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: fadeIn 0.2s ease;
  background-color: rgba(0, 0, 0, 0.4);
}

.Popup .content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 380px;
  min-height: 420px;
  max-height: 520px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  border: 1px solid #f1f1f3;
  margin: auto;
  background: #f1f1f3;
  border-radius: 10px;
  box-shadow: -1px 4px 8px rgba(0, 0, 0, 0.25);
  color: #222;
}

.Popup .content .close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Popup .content .close:hover {
  cursor: pointer;
}

.Popup .content .address {
  width: 80%;
  flex-basis: 56px;
  margin-top: 4px;
  font-family: Nunito;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  text-align: center;
  word-break: break-all;
}

.Popup .content .summary {
  display: flex;
  width: 300px;
  flex-basis: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  margin: 16px;
  background: #fff;
  border-radius: 10px;
  font-size: 18px;
  line-height: 30px;
}

.Popup .content .summary .liquidity {
  flex-direction: row;
  justify-content: center;
}

.Popup .content .divider {
  width: 144px;
  border-top: 1px solid #e1e1e1;
  margin: 8px;
}

.Popup .content .connect,
.Popup .content .claim {
  width: 196px;
  flex-basis: 46px;
  padding: 0;
  background-color: transparent;
  border-radius: 23px;
  color: #222;
}

.Popup .content .connect {
  border: 2px solid #f6841b;
}

.Popup .content .connect:hover,
.Popup .content .connect:active {
  background: linear-gradient(180deg, #e4751b 0%, #f6841b 100%);
  box-shadow: -5px -5px 30px #fff, 5px 5px 30px rgba(174, 174, 192, 0.5);
  color: #fff !important;
}

.Popup .content .connect:not(:disabled):not(.disabled):active:focus,
.Popup .content .connect:not(:disabled):not(.disabled).active:focus,
.Popup .content .connect:focus,
.Popup .content .connect.focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 132, 27, 0.5) !important;
}

.Popup .content .connect .dot {
  margin: 0 4px 2px;
}

.Popup .content .connect:hover .dot,
.Popup .content .connect:active .dot {
  fill: #fff;
}

.Popup .content .claim {
  border: 2px solid #9f1f63;
}

.Popup .content .claim:hover,
.Popup .content .claim:active {
  background: linear-gradient(180deg, #821e53 0%, #9f1f63 100%);
  box-shadow: -5px -5px 30px #fff, 5px 5px 30px rgba(174, 174, 192, 0.5);
  color: #fff !important;
}

.currency {
  color: #9f1f63;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border: 4px solid #fff;
  background-color: #9f1f63;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #821e53 0%, #9f1f63 100%);
}

::-webkit-scrollbar-thumb:active {
  background: #821e53;
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1184px;
  }
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn:focus,
.btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(159, 31, 99, 0.5);
}
